import { template as template_8311d430e65c41ad96572c7ad9103042 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8311d430e65c41ad96572c7ad9103042(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
