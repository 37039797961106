import { template as template_b5223273340d493989b621526456db33 } from "@ember/template-compiler";
const FKText = template_b5223273340d493989b621526456db33(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
