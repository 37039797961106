import { template as template_c1673e5c970b492fb640aac07edaab37 } from "@ember/template-compiler";
const FKLabel = template_c1673e5c970b492fb640aac07edaab37(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
